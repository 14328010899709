body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
    font-family: "TTFirsNeueRegular";
    font-style: normal;
    font-weight: normal;
    src: url("./assets/fonts/TTFirsNeueRegular.otf");
    src: url("./assets/fonts/TTFirsNeueRegular.otf") format("opentype");
}

@font-face {
    font-family: "TTFirsNeueMedium";
    font-style: normal;
    font-weight: normal;
    src: url("./assets/fonts/TTFirsNeueMedium.otf");
    src: url("./assets/fonts/TTFirsNeueMedium.otf") format("opentype");
}

@font-face {
    font-family: "icomoon";
    src: url("./assets/fonts/icons/icomoon.eot");
    src: url("./assets/fonts/icons/icomoon.eot") format("embedded-opentype"),
        url("./assets/fonts/icons/icomoon.ttf") format("truetype"),
        url("./assets/fonts/icons/icomoon.woff") format("woff"), url("./assets/fonts/icons/icomoon.svg") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: "player-icons";
    src: url("./assets/fonts/player-icons/player-icons.eot");
    src: url("./assets/fonts/player-icons/player-icons.eot") format("embedded-opentype"),
        url("./assets/fonts/player-icons/player-icons.ttf") format("truetype"),
        url("./assets/fonts/player-icons/player-icons.woff") format("woff"),
        url("./assets/fonts/player-icons/player-icons.svg") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: "device-icons";
    src: url("./assets/fonts/device-icons/device-icons.eot");
    src: url("./assets/fonts/device-icons/device-icons.eot") format("embedded-opentype"),
        url("./assets/fonts/device-icons/device-icons.ttf") format("truetype"),
        url("./assets/fonts/device-icons/device-icons.woff") format("woff"),
        url("./assets/fonts/device-icons/device-icons.svg") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

html {
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

body {
    width: 100%;
    font-size: 16px;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
}

#root {
    height: 100%;
    overflow: hidden;
}

* {
    box-sizing: border-box;
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0.25) transparent;
}

a {
    text-decoration: none;
}

::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.25);
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.5);
}
